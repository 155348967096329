import StarIcon from '@mui/icons-material/Star';
import { ClickAwayListener, Rating, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import DetailIcon from '../../../../Assets/Images/chatting.png';
import DropdownDownIcon from '../../../../Assets/Images/icon-dropdown-down.png';
import DropdownUpIcon from '../../../../Assets/Images/icon-dropdown-up.png';
import Loading from '../../../../Commons/Components/Loading';
import Popup from '../../../../Commons/Components/Popup';
import { POST } from '../../../../Commons/Utils/fetch';
import { abbreviationWeek } from '../constant.js';

function Review({ canWrite, title, hasReview, lectureType, id }) {
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      generalRating: 5,
      contentRating: 5,
      timeRating: 5,
      tutorRating: 5,
    },
  });
  const { formatMessage } = useIntl();

  const [generalPoint, setGeneralPoint] = useState(5);
  const [contentPoint, setContentPoint] = useState(5);
  const [timePoint, setTimePoint] = useState(5);
  const [tutorPoint, setTutorPoint] = useState(5);
  const [isSubmit, setIsSubmit] = useState(false);
  const onSubmit = async (data) => {
    if (isSubmit) return;
    setIsSubmit(true);
    await POST({
      url: `/class/${id}/review`,
      body: data,
      header: { EJE_API_KEY: localStorage.getItem('eje_token') },
    })
      .then((res) => {
        return res;
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmit(false);
          window.location.reload();
        }, 2000);
      });
  };

  return (
    <>
      <button
        className={`write_review_btn ${hasReview && 'has-review'}`}
        style={{ opacity: canWrite ? 1 : 0.3 }}
        onClick={() => {
          if (hasReview) return false;
          else if (!canWrite) {
            swal({
              icon: 'warning',
              text:
                lectureType === 'offline'
                  ? formatMessage({ id: 'REVIEW_WARNING_ENDDATE' })
                  : formatMessage({ id: 'REVIEW_WARNING_RATE' }),
            });
            return false;
          } else setOpen(true);
        }}
        disabled={hasReview}
      >
        {hasReview ? (
          <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT" />
        ) : (
          <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_TITLE" />
        )}
      </button>
      <Popup open={open} onClose={() => setOpen(false)} className="mobile-popup write-review-popup" modal>
        <form className="write-review-popup" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="write-review-popup-title">{title}</h3>
          <div className="write-review-popup-content">
            <div className="write-review-popup-content-form-fieldset">
              <div className="write-review-popup-content-general">
                <h5>
                  <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE" />
                </h5>
                <input
                  type="hidden"
                  {...register('generalRating', {
                    required: true,
                    value: generalPoint,
                  })}
                />
                <Rating
                  value={generalPoint}
                  emptyIcon={<StarIcon fontSize="inherit" />}
                  onChange={(event, newValue) => {
                    setGeneralPoint(newValue ?? 1);
                    setValue('generalRating', newValue ?? 1);
                  }}
                />
                <textarea
                  className={`write-review-popup-content-general-textarea ${errors.generalComment ? 'error' : ''}`}
                  aria-invalid={errors.generalComment ? 'true' : 'false'}
                  placeholder={formatMessage({
                    id: 'MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER',
                  })}
                  {...register('generalComment', {
                    required: true,
                  })}
                />
              </div>
              <div className="write-review-popup-content-detail">
                <div className="write-review-popup-content-detail-title">
                  <span>
                    <img src={DetailIcon} alt="detail" />
                  </span>
                  <p>
                    <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER" values={{ br: <br /> }} />
                  </p>
                </div>
                <div className="write-review-popup-content-detail-content">
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION',
                      }),
                    }}
                  />
                  <input
                    type="hidden"
                    {...register('contentRating', {
                      required: true,
                      value: contentPoint,
                    })}
                  />
                  <Rating
                    value={contentPoint}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setContentPoint(newValue ?? 1);
                      setValue('contentRating', newValue ?? 1);
                    }}
                  />
                  {contentPoint > 0 && contentPoint < 3 && (
                    <textarea
                      placeholder={formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                      })}
                      {...register('contentComment', {
                        required: true,
                      })}
                      className={`${errors.contentComment ? 'error' : ''}`}
                      aria-invalid={errors.contentComment ? 'true' : 'false'}
                    />
                  )}
                </div>
                <div className="write-review-popup-content-detail-time">
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION',
                      }),
                    }}
                  />
                  <input
                    type="hidden"
                    {...register('timeRating', {
                      required: true,
                      value: timePoint,
                    })}
                  />
                  <Rating
                    value={timePoint}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setTimePoint(newValue ?? 1);
                      setValue('timeRating', newValue ?? 1);
                    }}
                  />
                  {timePoint > 0 && timePoint < 3 && (
                    <textarea
                      placeholder={formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                      })}
                      {...register('timeComment', {
                        required: true,
                      })}
                      className={`${errors.timeComment ? 'error' : ''}`}
                      aria-invalid={errors.timeComment ? 'true' : 'false'}
                    />
                  )}
                </div>
                <div className="write-review-popup-content-detail-tutor">
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION',
                      }),
                    }}
                  />
                  <input
                    type="hidden"
                    {...register('tutorRating', {
                      required: true,
                      value: tutorPoint,
                    })}
                  />
                  <Rating
                    value={tutorPoint}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setTutorPoint(newValue ?? 1);
                      setValue('tutorRating', newValue ?? 1);
                    }}
                  />
                  {tutorPoint > 0 && tutorPoint < 3 && (
                    <textarea
                      placeholder={formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                      })}
                      {...register('tutorComment', {
                        required: true,
                      })}
                      className={`${errors.tutorComment ? 'error' : ''}`}
                      aria-invalid={errors.tutorComment ? 'true' : 'false'}
                    />
                  )}
                </div>
                <div className="write-review-popup-content-detail-comments">
                  <div className="question">
                    <strong>
                      <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION" />
                    </strong>
                  </div>
                  <textarea
                    className="write-review-popup-content-general-textarea"
                    placeholder={formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER',
                    })}
                    {...register('suggestionComment')}
                    className={`${errors.suggestionComment ? 'error' : ''}`}
                    aria-invalid={errors.suggestionComment ? 'true' : 'false'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`write-review-popup-error-alert ${Object.keys(errors).length > 0 ? '' : 'hidden'}`}>
            <span>
              <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR" />
            </span>
          </div>
          <div style={{ position: 'sticky', bottom: 0, backgroundColor: '#FFFFFF' }}>
            <button type="submit" className="write-review-popup-content-button">
              {isSubmit ? <Loading /> : <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT" />}
            </button>
          </div>
        </form>
      </Popup>
    </>
  );
}

async function postCertificate(lecture, userInfo) {
  const {
    id: FK_user_class_id,
    FK_user_id,
    FK_class: { title: lectureName },
    start_date: lectureStartDate,
    end_date: lectureEndDate,
  } = lecture;
  const { name: userName } = userInfo;
  const body = {
    FK_user_class_id,
    FK_user_id,
    lectureName,
    lectureStartDate,
    lectureEndDate,
    userName,
  };
  if (lecture.FK_class.listen_type === 'offline') {
    body.lectureRound = lecture.FK_class_offline_schedule.class_round;
  } else if (lecture.FK_class.listen_type === 'roundOnline') {
    body.lectureRound = lecture.FK_class_online_schedule.class_round;
  } else {
    body.lectureRound = 0;
  }
  try {
    const certificate = await POST({
      url: '/class/certificate',
      body,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
    return certificate;
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

async function getCertificate(lecture, userInfo) {
  if (lecture.is_certificate_file) {
    window.open(lecture.certificate);
  } else if (!lecture.is_certificate_file && lecture.certificate) {
    window.open(`/certificate/${lecture.certificate}`);
  } else {
    const { success, code } = await postCertificate(lecture, userInfo);
    if (success) {
      window.open(`/certificate/${code}`);
    } else {
      swal({
        title: 'Error',
        text: 'Something went wrong',
        icon: 'error',
      });
    }
  }
}

function CertificateButton({ lecture, certificateUrl, userInfo }) {
  const acceptRate = +lecture.completion_rate >= +lecture.FK_class.pass_rate;
  const afterEndDate = lecture.FK_class.host === 'gov' || dayjs().isAfter(dayjs(lecture.end_date).add(1, 'day'));
  const isCompletion = acceptRate && afterEndDate; // 수료 기준 통과여부
  const statusPostSurvey = lecture.survey_post_done; // 설문조사 여부 -1:미응답, 0:설문없음, 1:응답
  const isCertTypeFile = lecture.is_certificate_file;

  const newPreSurvey = lecture.FK_class.Class_Surveys?.find((survey) => survey.timing === 'before');
  const newPostSurvey = lecture.FK_class.Class_Surveys?.find((survey) => survey.timing === 'after');

  let buttonText = '-';

  const getButtonText = (isSurveyCompleted, isCertTypeFile) => {
    if (isSurveyCompleted) {
      if (lecture.FK_class.host === 'gov') {
        return isCertTypeFile ? '발급완료' : '발급중';
      } else {
        return '발급완료';
      }
    }
    return '미완료';
  };

  const isCompletedPreCondition = newPreSurvey ? newPreSurvey.isCompleted : true;

  if (isCompletion) {
    if (!!lecture.FK_class.survey_post || !!lecture.FK_class.survey_pre) {
      // 기존 설문 조사가 있는 경우
      if (statusPostSurvey === -1) {
        // 설문조사 미응답
        buttonText = '신청';
      } else if (statusPostSurvey === 0 || statusPostSurvey === 1) {
        // 설문조사 없음
        if (lecture.FK_class.host === 'gov') {
          if (isCertTypeFile) {
            buttonText = '발급완료';
          } else {
            buttonText = '발급중';
          }
        } else {
          buttonText = '확인';
        }
      }
    } else if (isCompletedPreCondition) {
      // 신규 설문조사의 결과에 따른 처리
      if (newPostSurvey) {
        // 수강 후 설문조사가 있는 경우
        buttonText = getButtonText(newPostSurvey.isCompleted, isCertTypeFile);
      } else {
        buttonText = getButtonText(true, isCertTypeFile);
      }
    }
  } else if (lecture.completion_rate > 0) {
    if (lecture.FK_class.host === 'gov') {
      buttonText = '미완료';
    }
  }

  return (
    acceptRate &&
    dayjs().isAfter(dayjs(lecture.end_date).add(1, 'day')) && (
      <>
        <button
          className="certificate_wrapper"
          style={{
            backgroundColor: 'transparent',
            color: '#F22672',
            borderColor: '#F22672',
            borderStyle: 'solid',
            borderWidth: '1px',
            marginTop: '8px',
            padding: '8px',
            textAlign: 'center',
            fontSize: '13px',
            borderRadius: '5px',
            width: '100%',
          }}
          onClick={() => {
            if (buttonText === '신청') {
              swal({
                title: '수료증 발급 신청',
                text: '설문조사를 완료한 분들만 수료증 발급 신청이 가능합니다.(약 1일 소요)\n',
                buttons: ['취소', '설문 참여 및 발급 신청'],
                closeOnClickOutside: false,
              }).then((willTakeSurvey) => {
                if (willTakeSurvey) {
                  window.open(lecture.FK_class.survey_post);
                }
              });
            } else if (buttonText === '발급중') {
            } else {
              getCertificate(lecture, userInfo);
            }
          }}
        >
          수료증 {buttonText}
        </button>
        {certificateUrl && <a ref={certificateUrlRef} href={certificateUrl} target="_blank" />}
      </>
    )
  );
}

const LectureCard = ({ currentTab, lecture, originLectures, userInfo }) => {
  const location = useLocation();
  const history = useHistory();
  const type = lecture.FK_class.listen_type; // online || offline
  const classType = lecture.FK_class.class_type; // single || package

  const id = lecture.id;
  const now = dayjs();
  const start = lecture.start_date;
  const end = lecture.end_date;
  const count_left_day = lecture.count_left_day;
  const class_status = lecture.class_status;
  const completion_rate = lecture.completion_rate;
  const isEnded = class_status === 'end';
  const isPending = class_status === 'pending';
  const isBetween = class_status === 'ongoing';
  const schedule =
    currentTab === 'online'
      ? lecture.FK_class.listen_type === 'roundOnline'
        ? lecture.FK_class_online_schedule
        : lecture.FK_class.ClassOnlineSchedule
      : lecture.FK_class.listen_type === 'consulting'
      ? lecture.FK_class_consulting_schedule
      : lecture.FK_class_offline_schedule;

  const classStatusFormatId = 'LECTURE_STATUS_' + class_status.toUpperCase();

  const [canWrite, setCanWrite] = useState(false);
  const [open, setOpen] = useState(false);

  const showLectureLists = originLectures.filter((originLecture) => originLecture.FK_class_id === lecture.FK_class_id);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [certificateUrl, setCertificateUrl] = useState(undefined);
  const certificateUrlRef = useRef();

  async function postCertificate(lecture, userInfo) {
    const {
      id: FK_user_class_id,
      FK_user_id,
      FK_class: { title: lectureName },
      start_date: lectureStartDate,
      end_date: lectureEndDate,
    } = lecture;
    const { name: userName } = userInfo;
    const body = {
      FK_user_class_id,
      FK_user_id,
      lectureName,
      lectureStartDate,
      lectureEndDate,
      userName,
    };
    if (lecture.FK_class.listen_type === 'offline') {
      body.lectureRound = lecture.FK_class_offline_schedule.class_round;
    } else if (lecture.FK_class.listen_type === 'roundOnline') {
      body.lectureRound = lecture.FK_class_online_schedule.class_round;
    } else {
      body.lectureRound = 0;
    }
    try {
      const certificate = await POST({
        url: '/class/certificate',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });
      return certificate;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }

  useEffect(() => {
    if (certificateUrl) {
      certificateUrlRef.current.click();
    }
  }, [certificateUrl]);

  const openClassroom = useCallback((lecture) => {
    if (lecture?.survey_pre_done === -1) {
      swal({
        title: '사전 설문조사를 완료하셨습니까?',
        buttons: [
          {
            text: '아니오',
            value: null,
            visible: true,
            closeModal: true,
          },
          {
            text: '예',
            value: true,
            visible: true,
            closeModal: true,
          },
        ],
        closeOnClickOutside: false,
      }).then((value) => {
        if (value) {
          window.open(`/classroom/${lecture.id}/${lecture.FK_class.id}`);
        } else {
          if (lecture?.FK_class?.survey_pre) {
            window.open(`/classroom/${lecture.id}/${lecture.FK_class.id}`);
            window.open(lecture.FK_class.survey_pre);
          } else {
            swal({
              title: 'Error',
              text: 'Something went wrong',
              icon: 'error',
            });
          }
        }
      });
    } else {
      window.open(`/classroom/${lecture.id}/${lecture.FK_class.id}`);
    }
  });

  if (type !== 'offline' && type !== 'consulting') {
    const isPackage = lecture.FK_class.class_type === 'package';

    return (
      // type === online
      <div className="lecture_item">
        <strong className={`lecture_item_status ${isEnded ? 'isEnded' : ''} ${isPending ? 'isPending' : ''}`}>
          {class_status ? <FormattedMessage id={classStatusFormatId} /> : <div>-</div>}
        </strong>
        <h4 className={`lecture_item_title ${isEnded ? 'isEnded' : ''}`}>
          {classType === 'package' && (
            <span className="isPackage">
              <FormattedMessage id="PACKAGE" />
            </span>
          )}
          {lecture.FK_class.title}
        </h4>
        <div className="lecture_item_info">
          <ul>
            {!isPackage && (
              <li>
                <strong>
                  <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_OPTION" />
                </strong>
                <span>{schedule && schedule.title ? schedule.title : '-'}</span>
              </li>
            )}
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_PERIOD" />
              </strong>
              <div>
                <span>
                  {start || '-'} ~ {end || '-'}
                </span>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <ul>
                        {showLectureLists.map((lecture, idx) => (
                          <li key={idx}>
                            {`${idx + 1}회차 수강 기간 : ${lecture.start_date || '-'} ~ ${lecture.end_date || '-'}`}
                          </li>
                        ))}
                      </ul>
                    }
                  >
                    {open ? (
                      <img src={DropdownUpIcon} className="tooltip_dropdown" onClick={handleTooltipClose} />
                    ) : (
                      <img src={DropdownDownIcon} className="tooltip_dropdown" onClick={handleTooltipOpen} />
                    )}
                  </Tooltip>
                </ClickAwayListener>
              </div>
            </li>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_REMAIN_DAYS" />
              </strong>
              <span>
                {Number.isInteger(count_left_day) ? count_left_day : '-'}
                <FormattedMessage id="DAY" />
              </span>
            </li>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_ATTENDANCE_RATE" />
              </strong>
              <span>{Number.isInteger(completion_rate) ? `${completion_rate}%` : '-'}</span>
            </li>
          </ul>
        </div>
        {isPackage ? (
          <Link
            to={{
              pathname: location.pathname,
              state: {
                shouldShowPackageDetail: true,
                packageId: lecture.id,
              },
            }}
          >
            <span className="go_to_classroom_btn">
              <FormattedMessage id="GO_TO_CLASSLIST" />
            </span>
          </Link>
        ) : (
          isBetween && (
            // <Link
            //   to={{
            //     pathname: `/classroom/${lecture.id}`,
            //     state: { targetClassId: lecture.FK_class.id }
            //   }}
            // >
            //   <span className="go_to_classroom_btn">
            //     <FormattedMessage id="GO_TO_CLASSROOM" />
            //   </span>
            // </Link>
            <button
              style={{
                width: '100%',
              }}
              onClick={() => openClassroom(lecture)}
            >
              <span className="go_to_classroom_btn">
                <FormattedMessage id="GO_TO_CLASSROOM" />
              </span>
            </button>
          )
        )}
        {lecture.class_status === 'ongoing' && lecture.FK_class.url_attached_file && (
          <Link
            to={{
              pathname: lecture.FK_class.url_attached_file,
            }}
            target="_blank"
          >
            <span
              className="go_to_classroom_btn"
              style={{
                backgroundColor: 'transparent',
                color: '#F22672',
                borderColor: '#F22672',
                borderStyle: 'solid',
                borderWidth: '1px',
                marginTop: '8px',
              }}
            >
              <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE" />{' '}
              <FormattedMessage id="MY_PAGE_LECTURES_DOWNLOAD_FILE" />
            </span>
          </Link>
        )}
        <CertificateButton lecture={lecture} certificateUrl={certificateUrl} userInfo={userInfo} />
        {lecture.FK_class.host !== 'gov' && completion_rate >= 30 && (
          <Review
            canWrite={completion_rate >= 30}
            hasReview={lecture.class_review.length > 0}
            title={lecture.FK_class.title}
            lectureType={type}
            id={lecture.FK_class_id}
          />
        )}
      </div>
    );
  } else {
    // type === offline
    const offlineSchedule =
      type === 'offline' ? lecture.FK_class_offline_schedule : lecture.FK_class_consulting_schedule;
    const abbr = offlineSchedule
      ? offlineSchedule.class_dates
          .split('')
          .reduce((prev, date, index) => {
            if (parseInt(date)) {
              prev.push(abbreviationWeek[index]);
            }

            return prev;
          }, [])
          .map((abb, index) => (
            <span key={abb.key}>
              <FormattedMessage id={abb.key} />
              {abb.length - 1 !== index && <span>,</span>}
            </span>
          ))
      : null;

    const beforeSurvey = lecture.FK_class.Class_Surveys.find((survey) => survey.timing === 'before');
    const isDoneBeforeSurvey = beforeSurvey ? beforeSurvey.isCompleted : false;
    const afterSurvey = lecture.FK_class.Class_Surveys.find((survey) => survey.timing === 'after');
    const isDoneAfterSurvey = afterSurvey ? afterSurvey.isCompleted : false;

    return (
      <div className="lecture_item">
        <strong className={`lecture_item_status ${isEnded ? 'isEnded' : ''} ${isPending ? 'isPending' : ''}`}>
          {class_status ? <FormattedMessage id={classStatusFormatId} /> : <div>-</div>}
        </strong>

        <h4 className={`lecture_item_title ${isEnded ? 'isEnded' : ''}`}>
          {classType === 'package' && (
            <span className="isPackage">
              <FormattedMessage id="PACKAGE" />
            </span>
          )}
          {lecture.FK_class.title}
        </h4>

        <div className="lecture_item_info">
          <ul>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_OPTION" />
              </strong>
              <span>{schedule && schedule.title ? schedule.title : '-'}</span>
            </li>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_GENERATION" />
              </strong>
              <span>
                {offlineSchedule ? offlineSchedule.class_round : '-'}
                <FormattedMessage id="STANDARD" />
              </span>
            </li>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_PERIOD" />
              </strong>
              <div>
                <span>
                  {start || '-'} ~ {end || '-'}
                </span>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <ul>
                        {showLectureLists.map((lecture, idx) => (
                          <li key={idx}>
                            {`${idx + 1}회차 수강 기간 : ${lecture.start_date || '-'} ~ ${lecture.end_date || '-'}`}
                          </li>
                        ))}
                      </ul>
                    }
                  >
                    {open ? (
                      <img src={DropdownUpIcon} className="tooltip_dropdown" onClick={handleTooltipClose} />
                    ) : (
                      <img src={DropdownDownIcon} className="tooltip_dropdown" onClick={handleTooltipOpen} />
                    )}
                  </Tooltip>
                </ClickAwayListener>
              </div>
            </li>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_DATE" />
              </strong>
              <div>
                <span>{abbr ? abbr : '-'}</span>
                <span>
                  {`${offlineSchedule ? offlineSchedule.class_start_time : '-'} ~ ${
                    offlineSchedule ? offlineSchedule.class_end_time : '-'
                  }`}
                </span>
              </div>
            </li>
            <li>
              <strong>
                <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_PLACE" />
              </strong>
              <span>{offlineSchedule ? offlineSchedule.class_location : '-'}</span>
            </li>
          </ul>
        </div>

        {lecture.class_status === 'pending' || lecture.class_status === 'ongoing' ? (
          beforeSurvey ? (
            isDoneBeforeSurvey ? (
              <span
                className="go_to_classroom_btn"
                style={{
                  backgroundColor: 'transparent',
                  color: '#a2a2a2',
                  borderColor: '#a2a2a2',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  marginTop: '8px',
                }}
              >
                사전 설문 완료
              </span>
            ) : (
              <Link
                to={{
                  pathname: `/survey/${beforeSurvey.FK_survey_id}?classId=${lecture.FK_class.id}&userClassId=${lecture.id}`,
                  state: { classId: lecture.FK_class.id, userClassId: lecture.id },
                }}
              >
                <span
                  className="go_to_classroom_btn"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#F22672',
                    borderColor: '#F22672',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    marginTop: '8px',
                  }}
                >
                  사전 설문
                </span>
              </Link>
            )
          ) : (
            '-'
          )
        ) : afterSurvey ? (
          isDoneAfterSurvey ? (
            <span
              className="go_to_classroom_btn"
              style={{
                backgroundColor: 'transparent',
                color: '#a2a2a2',
                borderColor: '#a2a2a2',
                borderStyle: 'solid',
                borderWidth: '1px',
                marginTop: '8px',
              }}
            >
              사후 설문 완료
            </span>
          ) : (
            <Link
              to={{
                pathname: `/survey/${afterSurvey.FK_survey_id}?classId=${lecture.FK_class.id}&userClassId=${lecture.id}`,
                state: { classId: lecture.FK_class.id, userClassId: lecture.id },
              }}
            >
              <span
                className="go_to_classroom_btn"
                style={{
                  backgroundColor: 'transparent',
                  color: '#F22672',
                  borderColor: '#F22672',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  marginTop: '8px',
                }}
              >
                사후 설문
              </span>
            </Link>
          )
        ) : (
          '-'
        )}

        {(lecture.class_status === 'ongoing' || lecture.FK_class_id === 465) && lecture.FK_class.url_attached_file && (
          <Link
            to={{
              pathname: lecture.FK_class.url_attached_file,
            }}
            target="_blank"
          >
            <span
              className="go_to_classroom_btn"
              style={{
                backgroundColor: 'transparent',
                color: '#F22672',
                borderColor: '#F22672',
                borderStyle: 'solid',
                borderWidth: '1px',
                marginTop: '8px',
              }}
            >
              <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE" />{' '}
              <FormattedMessage id="MY_PAGE_LECTURES_DOWNLOAD_FILE" />
            </span>
          </Link>
        )}

        {dayjs(end).isBefore(now) && (
          <>
            <button
              className="certificate_wrapper"
              style={{
                backgroundColor: 'transparent',
                color: '#F22672',
                borderColor: '#F22672',
                borderStyle: 'solid',
                borderWidth: '1px',
                marginTop: '8px',
                padding: '8px',
                textAlign: 'center',
                fontSize: '13px',
                borderRadius: '5px',
                width: '100%',
              }}
              onClick={() => getCertificate(lecture, userInfo)}
            >
              수료증 확인
            </button>
            {certificateUrl && <a ref={certificateUrlRef} href={certificateUrl} target="_blank" />}
          </>
        )}
        {dayjs(end).isBefore(now) && (
          <Review
            canWrite={dayjs(end).isBefore(now)}
            hasReview={lecture.class_review.length > 0}
            title={lecture.FK_class.title}
            lectureType={type}
            id={lecture.FK_class_id}
          />
        )}
      </div>
    );
  }
};

export default LectureCard;
